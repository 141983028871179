import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { EmptyComponent } from './shared/components/empty/empty.component';
import { Path, MenuRoute } from './core/navigation/navigation-menu';
import { MsalGuard } from '@azure/msal-angular';
import { ForbiddenComponent } from './shared/components/forbidden/forbidden.component';
import { Role } from './shared/types/account.types';
import { DeactivateGuard } from './core/service/deactivat.guard';

// @formatter:off
const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: MenuRoute.CHAT },
            {
                path: Path.ORGANISATIONS,
                canActivate: [MsalGuard, AuthGuard],
                loadChildren: () => import('app/modules/organisation/organisation.routes').then(m => m.ORGANISATION_ROUTES),
            },
            {
                path: Path.CHAT,
                canActivate: [MsalGuard, AuthGuard],
                runGuardsAndResolvers: 'always',
                loadChildren: () => import('app/modules/chat/chat.routes').then(m => m.CHAT_ROUTES),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.CENTRES,
                canActivate: [MsalGuard, AuthGuard],
                canDeactivate: [DeactivateGuard],
                runGuardsAndResolvers: 'always',
                loadComponent: () => import('app/modules/centre/centre-list/centre-list.component').then(m => m.CentreListComponent),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.CHANNELS,
                canActivate: [MsalGuard, AuthGuard],
                canDeactivate: [DeactivateGuard],
                runGuardsAndResolvers: 'always',
                loadComponent: () => import('app/modules/channel/channel-management/channel-management.component').then(m => m.ChannelManagementComponent),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.CAMPAIGNS,
                canActivate: [MsalGuard, AuthGuard],
                canDeactivate: [DeactivateGuard],
                runGuardsAndResolvers: 'always',
                loadComponent: () => import('app/modules/campaign/campaign-list/campaign-list.component').then(m => m.CampaignListComponent),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.INTEGRATIONS,
                canActivate: [MsalGuard, AuthGuard],
                runGuardsAndResolvers: 'always',
                loadComponent: () => import('app/modules/integration/integration-list/integration-list.component').then(m => m.IntegrationListComponent),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.DIALOGUE_AGENTS,
                canActivate: [MsalGuard, AuthGuard],
                runGuardsAndResolvers: 'always',
                loadComponent: () => import('app/modules/dialogue-agent/dialogue-agent-list/dialogue-agent-list.component').then(m => m.DialogueAgentListComponent),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.THIRD_PARTY_INTEGRATIONS,
                canActivate: [MsalGuard, AuthGuard],
                runGuardsAndResolvers: 'always',
                loadComponent: () =>
                    import('app/modules/third-party-integration/third-party-integration-list/third-party-integration-list.component').then(
                        m => m.ThirdPartyIntegrationListComponent
                    ),
                data: {
                    roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                path: Path.FORBIDDEN,
                component: ForbiddenComponent,
                canActivate: [MsalGuard, AuthGuard],
            },

            { path: '**', pathMatch: 'full', redirectTo: MenuRoute.CHAT },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: Path.LOGIN,
                component: EmptyComponent,
                canActivate: [NoAuthGuard],
            },
        ],
    },
];

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledNonBlocking',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
